<template>
  <b-form
    class="bg-white p-2"
    @submit.prevent
  >

    <h3 class="mb-2">
      Warunki umowy (umowa o dzieło)
    </h3>
    <b-row>

      <!-- przedmiot umowy -->
      <b-col cols="12">
        <b-form-group
          label="Przedmiot umowy"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Przedmiot umowy"
          />
        </b-form-group>
      </b-col>

      <!-- Stawka kosztów uzyskania przychodów -->
      <b-col cols="12">
        <b-form-group
          label="Etat"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="rate"
          />
        </b-form-group>
      </b-col>

      <!-- Data podpisania -->
      <b-col cols="12">
        <b-form-group
          label="Data podpisania"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Data rozpoczęcia -->
      <b-col cols="12">
        <b-form-group
          label="Data rozpoczęcia"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Data zakończenia -->
      <b-col cols="12">
        <b-form-group
          label="Data zakończenia"
          label-for=""
          label-cols-md="4"
        >
          <flat-pickr
            class="form-control"
          />

        </b-form-group>
      </b-col>

      <!-- Sposób rozliczania -->
      <b-col cols="12">
        <b-form-group
          label="Sposób rozliczania"
          label-for=""
          label-cols-md="4"
        >
          <v-select

            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="SettlementMethod"
          />
        </b-form-group>
      </b-col>

      <!-- Kwota -->
      <b-col cols="12">
        <b-form-group
          label="Kwota (brutto)"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Kwota brutto"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label=""
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Dodaj umowę
          </b-button>
        </b-form-group>
      </b-col>

    </b-row>

  </b-form>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rate: [{ title: 'Standardowa 20%' }, { title: 'Ze zbyciem praw autorskich 50%' }],
      RetirementAndDisabilityIsurance: [{ title: 'Obowiązkowe' }, { title: 'Dobrowolne' }, { title: 'Brak' }],
      MedicalInsurance: [{ title: 'Tak' }, { title: 'Nie' }, { title: 'Brak' }],
      HealthInsurance: [{ title: 'Tak' }, { title: 'Nie' }, { title: 'Brak' }],
      AccidentInsurance: [{ title: 'Tak' }, { title: 'Nie' }, { title: 'Brak' }],
      LaborFund: [{ title: 'Tak' }, { title: 'Nie' }, { title: 'Brak' }],
      BridgePensionFund: [{ title: 'Tak' }, { title: 'Nie' }, { title: 'Brak' }],
      SettlementMethod: [{ title: 'Kwotowo' }, { title: 'Godzinowo' }],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
