<template>

  <div>

    <div v-if="$route.params.contractType === 'classicContract'">
      <ClassicContract />
    </div>

    <div v-if="$route.params.contractType === 'mandateContract'">
      <MandateContract />
    </div>

    <div v-if="$route.params.contractType === 'contractWork'">
      <ContractWork />
    </div>

  </div>

</template>

<script>

import ClassicContract from './types/ClassicContract.vue'
import MandateContract from './types/MandateContract.vue'
import ContractWork from './types/ContractWork.vue'

export default {
  components: {
    ClassicContract,
    MandateContract,
    ContractWork,

  },
}
</script>

<style>

</style>
